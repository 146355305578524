/* Deafault Colors */
body {
  color: #000;
}

.main-color {
  color: #020202;
}

.accent-color {
  color: #9c9c9c;
}

.white-color {
  color: #fff;
}

.gs-important {
  color: #9c9c9c;
}

.bg-accent {
  background: #9c9c9c;
}

.bg-main {
  background: #020202;
}

.bg-gray {
  background: #f2f2f2;
}

.bg-white {
  background: #fff;
}

.gs-main-container.gs-half-gray-bg:before {
  background: #f2f2f2;
}

.gs-breadcrumb ul span {
  color: #959595;
}

.gs-spinner .gs-path {
  stroke: #020202;
}

.gs-section.bg-brd-top {
  border-color: #e9e9e9;
}

.gs-section-wrap.gs-sidebar-pattern:before {
  background: #fff;
  border-color: #e9e9e9;
}

/* Custom Scroll Colors */
.scrollable .scroll-bar.vertical {
  background: #020202;
}
.scrollable .scroll-bar.vertical .thumb {
  background: #020202;
}

.gs-scroll-down strong {
  border-color: #fff;
  color: #fff;
}

.gs-preview {
  background: #f2f2f2;
  text-align: center;
  padding: 10px 15px;
}
.gs-preview p {
  display: inline-block;
  max-width: 550px;
  text-align: left;
  vertical-align: bottom;
}

/* Qiuck View Pop Up Custom Styles */
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-content {
  background: #fff;
  border: none;
  padding: 20px 20px 15px;
  border: 1px solid #e5e5e5;
  box-shadow: none;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-close {
  position: fixed;
  top: 25px;
  right: 25px;
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #000;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-close span {
  top: 0;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-close:hover {
  color: #959595;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-gallery {
  margin: 0 25px 0 0;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-main {
  height: 0;
  padding-top: 120%;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-main:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.052);
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-main img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  transform: translate3d(-50%, -50%, 0);
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-thumbs {
  margin: 2px -2px;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-thumb {
  padding: 2px;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-thumb a {
  border: 0;
  position: relative;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-thumb a:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.052);
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-thumb.active a {
  opacity: 1;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-thumb.active a:after {
  background: rgba(0, 0, 0, 0.1);
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-copy-text h5 {
  font-size: 21px;
  font-family: "Arimo", sans-serif;
  line-height: 1.1;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-text {
  margin-top: 10px;
  height: 250px;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-meta {
  margin-top: 10px;
  font-size: 12px;
  line-height: inherit;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-meta strong {
  font-size: 12px;
  font-weight: normal;
  color: #959595;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-read-more {
  background: rgba(242, 242, 242, 0.9);
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-cart-loading {
  background: #fff;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-cart-loading span {
  background: transparent;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-cart-loading span:before {
  color: #020202;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-form {
  padding: 0;
  margin: 0 0 0 20px;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-cart-form {
  background: transparent;
  border: none;
  padding: 5px 0 0 25px;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-cart-form .gs-qv-fld {
  margin-top: 15px;
  margin-bottom: 0;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-cart-form label {
  font-weight: normal;
  font-size: 12px;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-cart-form select {
  background: #fff;
  height: 30px;
  border-color: #e5e5e5;
  color: #959595;
  font-size: 12px;
  padding: 0 5px;
  box-shadow: none;
  outline: none;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-cart-form .gs-gv-tick {
  width: 25px;
  height: 28px;
  background: #fff;
  border: none;
  bottom: 1px;
  right: 1px;
  text-align: center;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-cart-form .gs-gv-tick span {
  display: inline-block;
  color: #9c9c9c;
  top: 5px;
  font-size: 9px;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-cart-form .gs-gv-total {
  display: none;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-cart-form input.gs-qv-text-fld {
  background: #fff;
  color: #000;
  border: none;
  height: 30px;
  width: 55px;
  text-align: center;
  border: 1px solid #e5e5e5;
  padding: 0 5px;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-cart-form .gs-qv-submit {
  display: block;
  width: 100%;
  text-align: center;
  background: #020202;
  border-color: #020202;
  padding: 11px 5px;
  border-radius: 0;
  font-size: 12px;
  font-weight: 300;
  border-width: 2px;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-cart-form .gs-qv-submit:hover {
  background: transparent;
  color: #000;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-price {
  text-align: left;
  margin-bottom: 0;
  padding-bottom: 5px;
  border-color: #e5e5e5;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-price span {
  padding: 0;
  font-size: 14px;
  color: #959595;
  margin-right: 2px;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-price strong {
  font-size: 16px;
  font-weight: 300;
  margin-left: 0;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-gv-total span {
  font-weight: bold;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-gv-total strong {
  font-size: 24px;
  color: #9c9c9c;
  font-weight: normal;
}
.gs-qv-pop-up.gs-qv-overwrite .gs-qv-error {
  position: absolute;
  width: auto;
  bottom: 30px;
  border: 1px solid #e5e5e5;
  font-size: 11px;
  padding: 5px;
  background: #fff;
  max-width: calc(100% - 45px);
  left: 35px;
}

.gs-footer {
  border-color: #e5e5e5;
}
.gs-footer .gs-footer-bottom {
  border-color: #e5e5e5;
}
.gs-footer .gs-footer-search input.gs-text-field {
  border-color: #e9e9e9;
}

.gs-header {
  background: #fff;
  border-color: #e5e5e5;
}

@media (max-width: 1025px) {
  .gs-nav-wrap {
    background: #fff;
  }
}

@media (max-width: 1025px) {
  .gs-head-links ul a {
    border-color: #e5e5e5;
  }
}

.gs-lang-menu .gs-lang-list {
  background: #fff;
  border-color: #e5e5e5;
}
.gs-lang-menu .gs-lang-list a {
  border-color: #e5e5e5;
}
@media (min-width: 1025px) {
  .gs-lang-menu .gs-lang-list a:hover {
    opacity: 0.6;
  }
}
.gs-lang-menu .gs-lang-list a.active {
  opacity: 0.6;
}

.gs-cat-nav {
  background: #fff;
  border-color: #e5e5e5;
}
.gs-cat-nav .gs-mobile-title {
  border-color: #e5e5e5;
}

.gs-open-nav a em {
  background: #000;
}

.gs-search {
  background: #fff;
  border-color: #e5e5e5;
}

.gs-search-form .gs-field-box input.gs-text-field {
  background: #fff;
  box-shadow: none;
}

@media (max-width: 1025px) {
  .gs-cat-nav-wrap {
    background: #fff;
  }
}

.gs-menu-item .gs-sub-menu {
  background: #fff;
  border-color: #e5e5e5;
}
.gs-menu-item .gs-sub-menu .gs-sub-menu {
  border-color: #e5e5e5;
}

@media (max-width: 1025px) {
  .gs-cart .gs-cart-qtty {
    background: #020202;
    color: #fff;
  }
}

.gs-cart-box {
  background: #fff;
  border-color: #e5e5e5;
}
.gs-cart-box .gs-cart-item {
  border-color: #e5e5e5;
}

.gs-view-more {
  border-color: #000;
  color: #fff;
  background: #000;
}
@media (min-width: 1025px) {
  .gs-view-more:hover {
    background: transparent;
    color: #000;
  }
}
.gs-view-more.transparent {
  color: #000;
  background: transparent;
}
@media (min-width: 1025px) {
  .gs-view-more.transparent:hover {
    background: #000;
    color: #fff;
  }
}
.gs-view-more.white {
  border-color: #e9e9e9;
  color: #000;
  background: #fff;
}
@media (min-width: 1025px) {
  .gs-view-more.white:hover {
    background: #020202;
    color: #fff;
  }
}
.gs-view-more.main-color {
  border-color: #020202;
  color: #fff;
  background: #020202;
}
@media (min-width: 1025px) {
  .gs-view-more.main-color:hover {
    background: transparent;
    color: #020202;
  }
}
.gs-view-more.accent-color {
  border-color: #9c9c9c;
  color: #fff;
  background: #9c9c9c;
}
@media (min-width: 1025px) {
  .gs-view-more.accent-color:hover {
    background: transparent;
    color: #9c9c9c;
  }
}
.gs-view-more.black {
  border-color: #000;
  background: #000;
  color: #fff;
}
@media (min-width: 1025px) {
  .gs-view-more.black:hover {
    background: transparent;
    color: #000;
  }
}

.gs-video-thumb {
  background: #020202;
  color: #fff;
}

div#gs-leasing-btn-prd {
  display: block;
}
div#gs-leasing-btn-prd.gs-overwrite .gs-leasing-btn {
  display: block;
  max-width: 280px;
  border-radius: 0;
}
@media (max-width: 1025px) {
  div#gs-leasing-btn-prd.gs-overwrite .gs-leasing-btn {
    max-width: 100%;
  }
}
div#gs-leasing-btn-prd.gs-overwrite .gs-leasing-icon {
  height: 25px;
}

div#gs-dsk-btn-prd {
  display: block;
}
div#gs-dsk-btn-prd.gs-overwrite .gs-dsk-btn {
  display: block;
  max-width: 280px;
  border-radius: 0;
}
@media (max-width: 1025px) {
  div#gs-dsk-btn-prd.gs-overwrite .gs-dsk-btn {
    max-width: 100%;
  }
}

div#gs-iute-btn-prd.gs-overwrite {
  margin: 10px 0 0;
  display: block;
}
div#gs-iute-btn-prd.gs-overwrite.gs-overwrite .gs-iute-btn {
  display: block;
  max-width: 280px;
  border-radius: 0;
}
@media (max-width: 1025px) {
  div#gs-iute-btn-prd.gs-overwrite.gs-overwrite .gs-iute-btn {
    max-width: 100%;
  }
}

.gs-preloader {
  background: #fff;
}

.gs-pattern.main-color {
  background: rgba(2, 2, 2, 0.85);
}
.gs-pattern.white {
  background: rgba(255, 255, 255, 0.85);
}
.gs-pattern.accent-color {
  background: rgba(156, 156, 156, 0.85);
}
.gs-pattern.dark {
  background: rgba(46, 46, 46, 0.85);
}
.gs-pattern.gray {
  background: rgba(46, 46, 46, 0.6);
}

.gs-bg-pattern {
  background: #f2f2f2;
}

/* Slider products */
.gs-products-slider .gs-item-data {
  background: #fff;
}
.gs-products-slider .gs-item-price span.gs-old-price {
  color: #959595;
}

/* Banners */
.gs-top-slider .gs-nav-dots .swiper-pagination-bullet {
  border-color: #000;
  background: transparent;
}
@media (max-width: 640px) {
  .gs-top-slider .gs-nav-dots .swiper-pagination-bullet {
    border-color: #fff;
  }
}
.gs-top-slider .gs-nav-dots .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  background: #000;
}
@media (max-width: 640px) {
  .gs-top-slider .gs-nav-dots .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    background: #fff;
  }
}

/* Grid Colors */
.gs-floated-grid .gs-item-data {
  background: #fff;
}
.gs-floated-grid .gs-item-data .gs-pattern {
  background: #000;
}
.gs-floated-grid .gs-item-price span.gs-old-price {
  color: #959595;
}

.gs-tags em {
  color: #fff;
  background: #020202;
}
.gs-tags em.gs-promo {
  background: #9c9c9c;
}

/* Detail Page */
.gs-thumbs-nav .gs-nav-btn {
  color: #000;
}

.gs-cart-form .gs-cart-preload {
  background: #fff;
}
.gs-cart-form .gs-select-wrap .gs-select-tick {
  background: #000;
}
.gs-cart-form .gs-select-wrap .gs-select-tick span {
  color: #fff;
}
.gs-cart-form .gs-select-wrap select {
  background: #fff;
}
.gs-cart-form .gs-pr-price span {
  color: #959595;
}
.gs-cart-form .item-info strong {
  color: #959595;
}
.gs-cart-form .gs-item-sum .gs-update-sum input {
  background: #fff;
}
.gs-cart-form .gs-cart-error {
  background: #FF0000;
  color: #fff;
}
.gs-cart-form .gs-cart-error em {
  color: #fff;
}
.gs-cart-form .gs-item-descr {
  border-color: #e9e9e9;
}
.gs-cart-form .gs-item-descr .gs-item-txt {
  color: #5e5e5e;
}

.gs-fast-order.gs-overwrite {
  background: transparent;
}
.gs-fast-order.gs-overwrite:before {
  background: #fff;
  border-color: #e5e5e5;
}
.gs-fast-order.gs-overwrite input.gs-text-fld {
  border-color: #e5e5e5;
  background: #fff;
}
.gs-fast-order.gs-overwrite .fast-order-sbm {
  background: transparent;
  color: #000;
  border-color: #020202;
}
@media (min-width: 1025px) {
  .gs-fast-order.gs-overwrite .fast-order-sbm:hover {
    background: #020202;
    color: #fff;
  }
}
.gs-fast-order.gs-overwrite .gs-order-error {
  color: #888888;
}

.gs-fast-order input.gs-text-fld {
  border-color: #b7b7b7;
  background: #fff;
}

/* Tabs In Detail Page */
.gs-tabs-body {
  border-color: #e5e5e5;
}
.gs-tabs-body .gs-tab-wrap {
  border-color: #e5e5e5;
}
.gs-tabs-body .gs-mobile-title {
  border-color: #e5e5e5;
}
.gs-tabs-body .gs-tab-table span {
  color: #5e5e5e;
}

/* Cart Page */
.gs-cart-container {
  border-color: #e5e5e5;
}

.gs-cart-table .gs-cart-row {
  border-color: #e5e5e5;
}
.gs-cart-table .gs-order-label {
  color: #959595;
}
.gs-cart-table .gs-order-quantity .gs-quantity-change input {
  background: transparent;
}
.gs-cart-table .gs-order-remove {
  color: #b7b7b7;
}
@media (min-width: 1025px) {
  .gs-cart-table .gs-order-remove:hover {
    color: #FF0000;
    text-decoration: underline;
  }
}

.gs-cart-action .gs-cart-total {
  border-color: #e5e5e5;
}

/* News Page */
.gs-news-list .gs-news-date {
  color: #959595;
}

/* News Detail Page */
.gs-news-data {
  color: #959595;
}

.gs-floated-grid.gs-news-grid.gs-rel-news {
  border-color: #e5e5e5;
}

/* Catalogue Page */
.gs-cat-filter .gs-ham-btn em {
  background: #000;
}
.gs-cat-filter .gs-select-head span {
  color: #959595;
}
.gs-cat-filter .gs-select-body {
  background: #fff;
  border-color: #e5e5e5;
}
.gs-cat-filter .gs-select-body label.active {
  background: #e5e5e5;
}
@media (min-width: 1025px) {
  .gs-cat-filter .gs-select-body label:hover {
    background: #e5e5e5;
  }
}

.gs-load-page-preloader .gs-loader {
  border-color: transparent;
  border-top-color: #9c9c9c;
  border-left-color: #9c9c9c;
}

.gs-pagination-nav a.current {
  background-color: #020202;
  color: #fff;
}
@media (min-width: 1025px) {
  .gs-pagination-nav a:hover {
    background-color: #020202;
    color: #fff;
  }
}

/* Sidebar */
.gs-clear-filters {
  color: #959595;
}

.gs-cat-filters {
  border-color: #e5e5e5;
  background: #fff;
}

.gs-cat-labels a {
  color: #020202;
}
.gs-cat-labels label span {
  border-color: #e5e5e5;
}
.gs-cat-labels label span:after {
  background: #020202;
}

.gs-cat-inline label {
  border-color: #e9e9e9;
}
.gs-cat-inline label.active {
  background: #020202;
  color: #fff;
}
@media (min-width: 1025px) {
  .gs-cat-inline label:hover {
    background: #020202;
    color: #fff;
  }
}

/* Custom Scroll Colors */
.mCSB_draggerContainer .mCSB_dragger {
  background: #020202;
}
.mCSB_draggerContainer .mCSB_draggerRail {
  background: #e5e5e5;
}

.gs-fake-sizer {
  background: #f2f2f2;
}

/* Ui Slider size and handle placement;*/
.gs-price-slider {
  border-color: #e9e9e9;
}
.gs-price-slider span.gs-price-title {
  color: #9c9c9c;
}

/* Ui Slider Styling*/
.noUi-background {
  background: #9c9c9c;
}

.noUi-connect {
  background: #9c9c9c;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}

.noUi-handle {
  background: #9c9c9c;
}

.noUi-active {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
}

.gs-iu-value {
  color: #888888;
  background: #fff;
  border-color: #e9e9e9;
}

/* Handle stripes;
 */
.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

/* Contacts */
.gs-contact-form input.gs-text-fld {
  background: #fff;
  border-color: #000;
}
.gs-contact-form .gs-textarea-fld {
  background: #fff;
  border-color: #000;
}
.gs-contact-form .gs-view-more {
  outline: none;
}
.gs-contact-form .gs-error-msg {
  background: #FF0000;
  color: #fff;
}
.gs-contact-form .gs-success-msg {
  background: #00b472;
  color: #fff;
}

/* FAQ Page */
.gs-faq .gs-faq-text {
  border-color: #e5e5e5;
}

.gs-faq-hint {
  border-color: #e5e5e5;
}

/* Pop Up Gallery */
.gs-shop-gallery {
  background: #fff;
}
.gs-shop-gallery .gs-swiper-pagination {
  background: #e5e5e5;
}
.gs-shop-gallery .gs-swiper-pagination .swiper-pagination-progressbar {
  background: #9c9c9c;
}

.breakthrough-banner .gs-text-wrap {
  color: #fff;
}

/* State Errors */
.gmbStdHover {
  background: rgba(255, 255, 255, 0.85);
}

.gmbStdMsg {
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  box-shadow: none;
}
@media (max-width: 640px) {
  .gmbStdMsg {
    width: 80%;
    padding: 20px;
  }
}
@media (max-width: 640px) {
  .gmbStdMsg .gmbStdMsgContent {
    padding: 10px 0;
  }
}
.gmbStdMsg .gmbStdMsgContent:before {
  display: none;
}
.gmbStdMsg .gmbStdMsgClose {
  background: transparent;
  border-color: #000;
  color: #000;
  border-width: 2px;
  border-radius: 0;
}
@media (min-width: 1025px) {
  .gmbStdMsg .gmbStdMsgClose:hover {
    background: #000;
    color: #fff;
  }
}