.gs-footer{
	border-color: $medium-gray;

	.gs-footer-bottom{
		border-color: $medium-gray;
	}

	.gs-footer-search{
		input.gs-text-field{
			border-color: $gray;
		}
	}

}