/* Slider products */
.gs-products-slider{

	.gs-item-data{
		background: $white;
	}

	.gs-item-price{
		span{

			&.gs-old-price{
				color: $darkest-gray;
			}
		}
	}

}

/* Banners */
.gs-top-slider{

	.gs-nav-dots{

		.swiper-pagination-bullet{
			border-color: $black;
			background: transparent;

			@include media(mobile){
				border-color: $white;
			}

			&.swiper-pagination-bullet-active{

				&:before{
					background:$black;

					@include media(mobile){
						background: $white;
					}
				}

			}
		}
	}
}

/* Grid Colors */

.gs-floated-grid{

	.gs-item-data{
		background: $white;

		.gs-pattern{
			background: $black;
		}
	}

	.gs-item-price{
		span{

			&.gs-old-price{
				color: $darkest-gray;
			}
		}
	}

}

.gs-tags{

	em{
		color: $white;
		background: $main-color;

		&.gs-promo{
			background: $accent-color;
		}
	}

}


/* Detail Page */

.gs-thumbs-nav{

	.gs-nav-btn{
		color: $black;
	}
}

.gs-cart-form{

	.gs-cart-preload{
		background: $white;
	}

	.gs-select-wrap{

		.gs-select-tick{
			background: $black;

			span{
				color: $white;
			}
		}
		select{
			background: $white;
		}
	}

	.gs-pr-price{

		span{
			color: $darkest-gray;
		}

	}

	.item-info{
		strong{
			color: $darkest-gray;
		}
	}

	.gs-item-sum{

		.gs-update-sum{

			input{
				background: $white;
			}
		}
	}

	.gs-cart-error{
		background: $red;
		color: $white;

		em{
			color: $white;
		}
	}

	.gs-item-descr{
		border-color: $gray;

		.gs-item-txt{
			color: $dark-gray;
		}
	}
}


.gs-fast-order.gs-overwrite{

	background: transparent;

	&:before{
		background: $white;
		border-color: $medium-gray;
	}


	input.gs-text-fld{
		border-color: $medium-gray;
		background: $white;
	}

	.fast-order-sbm{
		background: transparent;
		color: $text-color;
		border-color: $main-color;

		@include media(hover){

			&:hover{
				background: $main-color;
				color: $white;
			}

		}
	}

	.gs-order-error{
		color: $base-gray;
	}
}


.gs-fast-order{
	input.gs-text-fld{
		border-color: $light-gray;
		background: $white;
	}
}

/* Tabs In Detail Page */
.gs-tabs-body{
	border-color: $medium-gray;

	.gs-tab-wrap{
		border-color: $medium-gray;
	}

	.gs-mobile-title{
		border-color: $medium-gray;
	}

	.gs-tab-table{
		span{
			color: $dark-gray;
		}
	}
}

/* Cart Page */

.gs-cart-container{
	border-color: $medium-gray;
}

.gs-cart-table{

	.gs-cart-row{
		border-color: $medium-gray;

	}

	.gs-order-label{
		color: $darkest-gray;
	}

	.gs-order-quantity{

		.gs-quantity-change{

			input{
				background: transparent;
			}

		}

	}

	.gs-order-remove{
		color: $light-gray;
		
		@include media(hover){
			&:hover{
				color: $red;
				text-decoration: underline;
			}
		}
		
	}

}

.gs-cart-action{
	.gs-cart-total{
		border-color: $medium-gray;
	}
}

/* News Page */
.gs-news-list{
	.gs-news-date{
		color: $darkest-gray;
	}
}


/* News Detail Page */
.gs-news-data{
	color: $darkest-gray;
}

.gs-floated-grid.gs-news-grid.gs-rel-news{
	border-color: $medium-gray;
}

/* Catalogue Page */
.gs-cat-filter{

	.gs-ham-btn{
		em{
			background: $black;
		}
	}

	.gs-select-head{

		span{
			color: $darkest-gray;
		}

	}

	.gs-select-body{
		background: $white;
		border-color: $medium-gray;

		label{

			&.active{
				background: $medium-gray;
			}

			@include media(hover){
				&:hover{
					background: $medium-gray;
				}
			}

		}
	}
}

.gs-load-page-preloader{
	.gs-loader{
		border-color: transparent;
		border-top-color: $accent-color;
		border-left-color: $accent-color;
	}
}

.gs-pagination-nav{

	a{
		&.current{
			background-color: $main-color;
			color: #fff;
		}

		@include media(hover){
			&:hover{
				background-color: $main-color;
				color: #fff;
			}
		}
	}
}

/* Sidebar */
.gs-clear-filters{
	color: $darkest-gray;
}

.gs-cat-filters{
	border-color: $medium-gray;
	background: $white;
} 

.gs-cat-labels{

	a{
		color: $main-color;
	}

	label{

		span{
			border-color: $medium-gray;

			&:after{
				background: $main-color;
			}
		}

	}
}

.gs-cat-inline{
	
	label{
		border-color: $gray;

		&.active{
			background: $main-color;
			color: $white;
		}

		@include media(hover){
			&:hover{
				background: $main-color;
				color: $white;
			}
		}
	}

}

/* Custom Scroll Colors */
.mCSB_draggerContainer{

	.mCSB_dragger{
		background: $main-color;
	}

	.mCSB_draggerRail{
		background: $medium-gray;
	}
}

.gs-fake-sizer{
	background: $dirty-white;
}

/* Ui Slider size and handle placement;*/
.gs-price-slider{
	border-color: $gray;

	span.gs-price-title{
		color: $accent-color;
	}
}

/* Ui Slider Styling*/
.noUi-background {
	background: $accent-color;
}
.noUi-connect {
	background: $accent-color;
}

/* Handles and cursors;
 */
.noUi-draggable {
	cursor: w-resize;
}
.noUi-vertical .noUi-draggable {
	cursor: n-resize;
}
.noUi-handle {
	background: $accent-color;
}
.noUi-active {
	-webkit-transform: scale(.9);
	-ms-transform: scale(.9);
	-o-transform: scale(.9);
	transform: scale(.9);
}

.gs-iu-value{
	color: $base-gray;
	background: $white;
	border-color: $gray;
}

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {

}
.noUi-handle:after {
	left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
	width: 14px;
	height: 1px;
	left: 6px;
	top: 14px;
}
.noUi-vertical .noUi-handle:after {
	top: 17px;
}


/* Contacts */

.gs-contact-form{

	input.gs-text-fld{
		background: $white;
		border-color: $black;
	}

	.gs-textarea-fld{
		background: $white;
		border-color: $black;
	}

	.gs-view-more{
		outline: none;
	}

	.gs-error-msg{
		background:$red;
		color: $white;
	}

	.gs-success-msg{
		background:#00b472;
		color: $white;

	}
}

/* FAQ Page */
.gs-faq{

	.gs-faq-text{
		border-color: $medium-gray;
	}

}

.gs-faq-hint{
	border-color: $medium-gray;
}

/* Pop Up Gallery */
.gs-shop-gallery{
	background: $white;

	.gs-swiper-pagination{
		background: $medium-gray;

		.swiper-pagination-progressbar{
			background: $accent-color;
		}
	}
}


.breakthrough-banner{
	.gs-text-wrap{
		color: $white;
	}
}



/* State Errors */
.gmbStdHover{
	background: rgba($white,.85);
}

.gmbStdMsg{
	background: $white;
	border: 1px solid $medium-gray;
	border-radius: 0;
	box-shadow: none;

	@include media(mobile){
		width: 80%;
		padding: 20px;
	}

	.gmbStdMsgContent{
		
		@include media(mobile){
			padding: 10px 0;
		}

		&:before{
			display: none;
		}
	}

	.gmbStdMsgClose{
		background: transparent;
		border-color: $black;
		color: $black;
		border-width: 2px;
		border-radius:0;

		@include media(hover){

			&:hover{
				background: $black;
				color: $white;
			}
		
		}
	}
}
