body{
	color: $text-color;
}

.main-color{
	color: $main-color;
}

.accent-color{
	color: $accent-color;
}

.white-color{
	color: $white;
}

.gs-important{
	color: $accent-color;
}

.bg-accent{
	background: $accent-color;
}

.bg-main{
	background: $main-color;
}

.bg-gray{
	background: $dirty-white;
}

.bg-white{
	background: $white;
}

.gs-main-container{
	
	&.gs-half-gray-bg:before{
		background: $dirty-white;
	}
}

.gs-breadcrumb{
	
	ul{
		span{
			color: $darkest-gray;
		}
	}
	
}

.gs-spinner{
	.gs-path{
		stroke: $main-color;
	}
}

.gs-section{

	&.bg-brd-top{
		border-color: $gray;
	}
	
}

.gs-section-wrap{
	&.gs-sidebar-pattern:before{
		background: $white;
		border-color: $gray;
	}
}	

/* Custom Scroll Colors */
.scrollable{

	.scroll-bar{
		&.vertical{
			background: $main-color;

			.thumb{
				background: $main-color;
			}
		}
	}
}

.gs-scroll-down{
	strong{
		border-color: $white;
		color: $white;
	}
}

.gs-preview{
	background: $dirty-white;
	text-align: center;
	padding: 10px 15px;

	p{
		display: inline-block;
	    max-width: 550px;
	    text-align: left;
	    vertical-align: bottom;
	}
}

/* Qiuck View Pop Up Custom Styles */

.gs-qv-pop-up.gs-qv-overwrite{

	.gs-qv-content{
		background: $white;
		border: none;
		padding: 20px 20px 15px;
		border: 1px solid $medium-gray;
		box-shadow:none;
	}

	.gs-qv-close{
		position: fixed;
		top: 25px;
		right: 25px;
		background: transparent;
		border: 0;
		border-radius: 0;
		color: $text-color;


		span{
			top: 0;
		}

		&:hover{
			color: $darkest-gray;
		}
	}

	 .gs-qv-gallery{
	 	margin: 0 25px 0 0;
	 }

	.gs-qv-main{
		height: 0;
		padding-top: 120%;

		&:after{
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: rgba(0,0,0,.052);
		}

		img{
			position: absolute;
			top: 50%;
			left: 50%;
			max-width: 100%;
			max-height:100%;
			transform: translate3d(-50%, -50%, 0);
		}
	}

	.gs-qv-thumbs{
		margin: 2px -2px;
	}

	.gs-qv-thumb{
		padding: 2px;

		a{
			border: 0;
			position: relative;

			&:after{
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background: rgba(0,0,0,.052);
			}
		}

		&.active{

			a{
				opacity: 1;
				&:after{
					background: rgba($black, .1); 
				}
			}
		}
	}

	.gs-qv-copy-text{
		h5{
			font-size: 21px;
			font-family: 'Arimo', sans-serif;
			line-height: 1.1;
		}
	}

	.gs-qv-text{
		margin-top: 10px;
		height: 250px;
	}

	.gs-qv-meta{
		margin-top: 10px;
		font-size: 12px;
		line-height: inherit;

		strong{
			font-size: 12px;
			font-weight: normal;
			color: $darkest-gray;
		}
	}


	.gs-qv-read-more{
		background: rgba($dirty-white,.9);
	}

	.gs-qv-cart-loading{
		background: $white;

		span{
			background: transparent;

			&:before{
				color: $main-color;
			}
		}
	}

	.gs-qv-form{
		padding:0;
		margin: 0 0 0 20px;
	}

	.gs-qv-cart-form{
		background: transparent;
		border: none;
		padding: 5px 0 0 25px;

		.gs-qv-fld{
			margin-top: 15px;
			margin-bottom: 0;		
		}

		label{
			font-weight: normal;
			font-size: 12px;
		}

		select {
			background: $white;
			height: 30px;
			border-color: $medium-gray;
			color: $darkest-gray;
			font-size: 12px;
			padding: 0 5px;
			box-shadow: none;
			outline: none;		
		}


		.gs-gv-tick{
		    width: 25px;
		    height: 28px;
		    background: $white;
		    border: none;
		    bottom: 1px;
		    right: 1px;
		    text-align: center;

		    span{
		    	display: inline-block;
		    	color: $accent-color;
		    	top:5px;
		    	font-size: 9px;

		    }
		}

		.gs-gv-total{
			display: none;
		}

		input.gs-qv-text-fld{
			background: $white;
			color: $text-color;
			border:none;
			height: 30px;
			width: 55px;
			text-align: center;
			border: 1px solid $medium-gray;
			padding: 0 5px;
		}

		.gs-qv-submit{
			display: block;
			width: 100%;
			text-align: center;
			background: $main-color;
			border-color: $main-color;
			padding: 11px 5px;
			border-radius: 0;
			font-size: 12px;
    		font-weight: 300;
    		border-width: 2px ;


			&:hover{
				background:transparent;
				color: $text-color;
			}
		}
	}

	.gs-qv-price{
		text-align: left;
		margin-bottom: 0;
		padding-bottom: 5px;
		border-color: $medium-gray;
		
		span{
			padding:0;
			font-size: 14px;
			color: $darkest-gray;
			margin-right: 2px;
		}

		strong{
			font-size: 16px;
			font-weight: 300;
			margin-left: 0;
		}
	}

	.gs-gv-total{
		
		span{
			font-weight: bold;
		}

		strong{
			font-size: 24px;
			color: $accent-color;
			font-weight: normal;
		}
	}

	.gs-qv-error{
		position: absolute;
		width: auto;
		bottom: 30px;
		border: 1px solid $medium-gray;
		font-size: 11px;
		padding: 5px;
		background: $white;
		max-width: calc(100% - 45px);
		left: 35px;

	}
}