@charset 'UTF-8';

/* Deafault Colors */
@import
  'features/variables.scss',
  'features/base.scss',
  'features/footer.scss',
  'features/header.scss',
  'features/buttons.scss',
  'features/patterns.scss',
  'features/content.scss';