/// Basic Colors
$text-color: #000 !default;
$main-color: #020202 !default;
$accent-color: #9c9c9c !default;
$base-gray: #888888 !default;
$light-gray: #b7b7b7 !default;
$gray: #e9e9e9 !default;
$medium-gray: #e5e5e5 !default;
$dark-gray: #5e5e5e !default;
$darkest-gray: #959595 !default;
$middle-gray: #9a9a9a !default;
$white: #fff !default;
$black: #000 !default;
$light-black: #1f1f1f !default;
$red: #FF0000 !default;
$dirty-white: #f2f2f2 !default;



@function translate-media-condition($c) {
  $condMap: (
    "screen": "only screen",
    "print": "only print",
    "large": "(max-width: 1680px)",
    "large-medium": "(max-width: 1440px)",
    "laptop": "(max-width: 1370px)",    
    "laptop-medium": "(max-width: 1280px)",    
    "medium": "(max-width: 1150px)",
    "tablet": "(max-width: 1025px)",
    "tablet-medium": "(max-width: 950px)",
    "tablet-portrait": "(max-width: 770px)",
    "mobile": "(max-width: 640px)",
    "mobile-portrait": "(max-width: 440px)",
    "mobile-small": "(max-width: 350px)",
    "hover": "(min-width: 1025px)",
    "small-height": "(max-height: 768px)"
  );
  @return map-get( $condMap, $c );
}

@mixin media($args) {
  $query: "";
  @each $arg in $args {
    $op: "";
    @if ( $query != "" ) {
      $op: " and ";
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query}  { @content; }
}