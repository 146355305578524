.gs-preloader{
	background: $white;
}

.gs-pattern{

	&.main-color{
		background: rgba($main-color, .85);
	}

	&.white{
		background: rgba($white, .85);
	}

	&.accent-color{
		background: rgba($accent-color, .85);
	}

	&.dark{
		background: rgba(46,46,46,.85);
	}

	&.gray{
		background: rgba(46,46,46,.6);
	}
}

.gs-bg-pattern{
	background: $dirty-white;
}