.gs-header{
	background: $white;
	border-color: $medium-gray;
}

.gs-nav-wrap{
	@include media(tablet){
		background: $white;
	}
}

.gs-head-links{

	ul{
		a{		
			@include media(tablet){
				border-color:$medium-gray;
			}
		}
	}

}


.gs-lang-menu{

	.gs-lang-list{
		background: $white;
		border-color: $medium-gray;

	 	a{
			border-color: $medium-gray;

	 		@include media(hover){
	 			&:hover{
	 				opacity: .6;
	 			}
	 		}
	 		&.active{
	 			opacity: .6;
		   	}
	 	}

	}

}

.gs-cat-nav{
	background: $white;
	border-color: $medium-gray;

	.gs-mobile-title{
		border-color: $medium-gray;
	}
}

.gs-open-nav{

	a{
		em{
			background: $black;
		}
	}

}

.gs-search{
	background: $white;
	border-color: $medium-gray;
}

.gs-search-form{

	.gs-field-box{

		input.gs-text-field{
			background:$white;
			box-shadow: none;			
		}

	}
}


.gs-cat-nav-wrap{
	@include media(tablet){
		background:$white;
	}
}

.gs-menu-item{

	.gs-sub-menu{
		background: $white;
		border-color: $medium-gray;

		.gs-sub-menu{
			border-color: $medium-gray;
		}
	}
}

.gs-cart{
	.gs-cart-qtty{
		@include media(tablet){
			background: $main-color;
			color: $white;
		}
	}
}

.gs-cart-box{
	background: $white;
	border-color: $medium-gray;

	.gs-cart-item{
		border-color: $medium-gray;
	}
}