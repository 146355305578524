.gs-view-more{
	border-color: $text-color;
	color: $white;
	background: $text-color;
	
	@include media(hover){

		&:hover{
			background: transparent;
			color: $text-color;
		}

	}

	&.transparent{
		color: $text-color;
		background: transparent;

		@include media(hover){

			&:hover{
				background: $text-color;
				color: $white;
			}

		}
	}

	&.white{
		border-color: $gray;
		color: $text-color;
		background: $white;

		@include media(hover){

			&:hover{
				background: $main-color;
				color: $white;
			}

		}
	}

	&.main-color{
		border-color:$main-color;
		color: $white;
		background: $main-color;

		@include media(hover){

			&:hover{
				background: transparent;
				color: $main-color;
			}

		}
	}

	&.accent-color{
		border-color:$accent-color;
		color: $white;
		background: $accent-color;

		@include media(hover){

			&:hover{
				background: transparent;
				color: $accent-color;
			}

		}
	}

	&.black{
		border-color:$black;
		background: $black;
		color: $white;

		@include media(hover){
			&:hover{
				background: transparent;
				color: $black;
			}
		}
	}
}

.gs-video-thumb{
	background: $main-color;
	color: $white;
}

div#gs-leasing-btn-prd{
	display: block;	

	&.gs-overwrite{

		.gs-leasing-btn{
			display: block;
			max-width: 280px;
			border-radius: 0;

			@include media(tablet){
				max-width: 100%;
			}
		}

		.gs-leasing-icon{
			height: 25px;
		}

	}

}

div#gs-dsk-btn-prd{
	display: block;

	&.gs-overwrite{

		.gs-dsk-btn{
			display: block;
			max-width: 280px;
			border-radius: 0;

			@include media(tablet){
				max-width: 100%;
			}
		}

	}

}

div#gs-iute-btn-prd.gs-overwrite{
	margin: 10px 0 0;
	display: block;

	&.gs-overwrite{

		.gs-iute-btn{
			display: block;
			max-width: 280px;
			border-radius: 0;

			@include media(tablet){
				max-width: 100%;
			}
		}

	}
}

